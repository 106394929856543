<template>
  <v-app>
    <div class="d-sm-flex align-center justify-center mb-8 mt-8">
      <img
        :src="imageUrl"
        alt="Logo LiveTranslate"
        class="logo"
        @error="handleBrokenLogo"
      />
    </div>

    <div class="d-flex align-center justify-center flex-column mb-6 pa-3">
      <div>
        <p style="max-width: 1260px; text-align: justify">
          LiveTranslate helps operators speak to a participant in a different
          language in real time. Its speech recognition can be more accurate if
          we know the business domain and the possible topics of conversation.
          Latency, volume, and other parameters are also customizable.
        </p>
      </div>
      <ol>
        <li>
          Enter your phone number and the phone number of the person you’d like
          to call.
        </li>
        <li>Choose the language for each party on the call.</li>
        <li>Watch the output for the call transcript and translations.</li>
      </ol>
    </div>

    <div
      style="
        text-align: right;
        padding-right: 100px;
        font-size: 10px;
        color: red;
      "
    >
      <span>Last deployment: 3/27/2024</span>
    </div>

    <v-main>
      <Main />
    </v-main>
  </v-app>
</template>

<script>
import Main from "./components/Main";

export default {
  name: "App",

  components: {
    Main,
  },

  data: () => ({
    partnerId: null,
    brokenLogo: false,
  }),

  computed: {
    imageUrl() {
      if (this.partnerId && !this.brokenLogo) {
        return `/images/partner/${this.partnerId}/logo.png`;
      }
      return "/images/logo.png";
    },
  },

  methods: {
    handleBrokenLogo() {
      this.brokenLogo = true;
    },
  },

  created() {
    const uri = window.location.search.substring(1);
    const params = new URLSearchParams(uri);
    this.partnerId = params.get("partnerId");
  },
};
</script>

<style lang="scss">
.logo {
  margin: auto;
  max-width: 100%;
  max-height: 75px;
  height: auto;
  padding: 0 24px !important;
}

@media only screen and (max-width: 960px) {
  .v-application .mb-8 {
    margin-bottom: 12px !important;
  }
}
</style>
